module.exports = [{
      plugin: require('/codebuild/output/src867985526/src/github.com/NiteSpaceDev/nitespace-web/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography","omitGoogleFont":true},
    },{
      plugin: require('/codebuild/output/src867985526/src/github.com/NiteSpaceDev/nitespace-web/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src867985526/src/github.com/NiteSpaceDev/nitespace-web/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff5700","showSpinner":false},
    },{
      plugin: require('/codebuild/output/src867985526/src/github.com/NiteSpaceDev/nitespace-web/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-smartypants","options":{"dashes":"oldschool"}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":{"tsx":"tsx"},"aliases":{}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"sizeByPixelDensity":true}},{"resolve":"gatsby-remark-copy-linked-files","options":{}}]},
    },{
      plugin: require('/codebuild/output/src867985526/src/github.com/NiteSpaceDev/nitespace-web/node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","customEntries":[{"title":"Another Page","content":"Welcome to page 2","path":"/another-page/"}]}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"path","store":true},{"name":"content"},{"name":"tags"}],"resolvers":{"Mdx":{}}},
    },{
      plugin: require('/codebuild/output/src867985526/src/github.com/NiteSpaceDev/nitespace-web/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src867985526/src/github.com/NiteSpaceDev/nitespace-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
